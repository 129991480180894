import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { deleteUser } from "../Api/ListingApi"
import {  toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useDispatch } from "react-redux"
import firebase from 'gatsby-plugin-firebase';
import { navigate } from "gatsby";
import { logout } from "../../state/userSlice";
import { calenderResponse } from "../../state/calenderPopUp";
import { singlePropertyClear } from "../../state/detailSlice";
import { leadsId } from '../../state/userSlice';



export default function LicenseDelete(props) {
    const { showDelete, toggleDelete } = props;
    const dispatch = useDispatch()

     const handleDelete = async () => {
        let uid = localStorage.getItem("userInfo")
        uid = JSON.parse(uid)
        let id = uid.uid
        await deleteUser(id).then((res) => {
            toggleDelete()
            handleLogoutFirebase()
            toast.error(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            })
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleLogoutFirebase = async () => {
        await firebase
            .auth()
            .signOut()
        localStorage.removeItem("persist:root");
        localStorage.removeItem("userInfo");
        localStorage.removeItem('userId')
        navigate("/signin");
        dispatch(singlePropertyClear())
        dispatch(logout())
        dispatch(calenderResponse([]))
        dispatch(leadsId("")) 
    }


    return (
        <div>
            <Modal show={showDelete} onHide={toggleDelete}>
                <Modal.Header closeButton className="calendar-popup-margin teampopup-title" >
                    <Modal.Title className='form-field-label form-label-team '>License Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="team-popup-margin pb-0" >
                    <div>
                        <p style={{ textAlign: "center" }}>Are you sure want to delete this account?</p>
                    </div>
                    <div>
                        <Button className={style1.nobutton} onClick={toggleDelete}>
                            No
                        </Button>
                        <Button className={style1.yesbutton} onClick={handleDelete}>
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
